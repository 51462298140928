<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'vacant-jobs'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{vacantJob.id ? $t('create-user.edit-user') :  $t('vacant_jobs.create-new-vacant-job') }}</h1>
				<div class="action">
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<button class="btn-tbf white only-icon" @click="$router.push({name: 'vacant-jobs'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('vacant_jobs.vacant_job')}}</div>
					<div class="description">{{$t('vacant_jobs.form_details')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.name')}}*</label>
							<div v-if="$v.vacantJob.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.vacantJob.name.$error}">
							<div class="icon-left"><icon-role /></div>
							<div class="icon-right" v-if="vacantJob.name" @click.stop="vacantJob.name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('roles.name-ph')" class="input-text" v-model="vacantJob.name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.description')}}</label>
						</div>
						<div class="input-box">
							<textarea :placeholder="$t('roles.description-ph')" class="input-text" v-model="vacantJob.description"></textarea>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.job-data') }}</div>
					<div class="description">{{ $t('create-user.description-job-data') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.role') }}</label>
							<div class="create-new" @click="showModal('role', {from: 'create-user'})">{{ $t('create-user.add-role') }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-role /></div>
							<div class="icon-right" v-if="selectedRoles.length" @click.stop="selectedRoles = []"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags  multiselect_checkbox"
							v-bind:class="{populate: selectedRoles.length}"
							v-model="selectedRoles"
							:options="optionsRoles"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-role') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.department') }}</label>
							<div class="create-new" @click="showModal('department', {from: 'create-user'})">{{ $t('create-user.add-department') }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-department /></div>
							<div class="icon-right" v-if="selectedDepartments.length" @click.stop="selectedDepartments = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags multiselect_checkbox"
							v-bind:class="{populate: selectedDepartments.length}"
							v-model="selectedDepartments"
							:options="optionsDepartments"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-department') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.workstation') }}</label>
							<div class="create-new" @click="showModal('workstation', {from: 'create-user'})">{{ $t('create-user.add-workstation') }}</div>
							
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-building /></div>
							<div class="icon-right" v-if="selectedWorkstation != ''" @click.stop="selectedWorkstation = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
							v-model="selectedWorkstation"
							v-bind:class="{populate: selectedWorkstation != ''}"
							:options="optionsWorkstations"
							:allow-empty="true"
							:show-labels="false"
							track-by="id"
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-workstation') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.work-schedule') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-work-schedule /></div>
							<div class="icon-right" v-if="selectedWorkSchedule != ''" @click.stop="selectedWorkSchedule = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="select-tags-tbf"
								v-bind:class="{populate: selectedWorkSchedule != ''}"
								v-model="selectedWorkSchedule"
								:options="optionsWorkSchedules"
								:allow-empty="true"
								:show-labels="false"
								track-by="id"
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-template') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="info-form" v-if="selectedWorkSchedule != ''">
						<div class="info-work-schedule">
							<div class="title-template">{{ selectedWorkSchedule.name }}</div>
							<div class="intervals" v-if="selectedWorkSchedule.name != 'Custom'">
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.monday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'monday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'monday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'monday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.tuesday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'tuesday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'tuesday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'tuesday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.wednesday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'wednesday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'wednesday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'wednesday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.thursday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'thursday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'thursday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'thursday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.friday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'friday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'friday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'friday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.saturday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'saturday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'saturday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'saturday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.sunday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'sunday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'sunday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'sunday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
							</div>

							<div class="intervals" v-else>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.monday.start.$error || $v.customSchedule.monday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.monday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.monday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.monday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.monday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.monday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.tuesday.start.$error || $v.customSchedule.tuesday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.tuesday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.tuesday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.tuesday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.tuesday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.tuesday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.wednesday.start.$error || $v.customSchedule.wednesday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.wednesday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.wednesday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.wednesday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.wednesday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.wednesday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.thursday.start.$error || $v.customSchedule.thursday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.thursday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.thursday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.thursday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.thursday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.thursday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.friday.start.$error || $v.customSchedule.friday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.friday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.friday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.friday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.friday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.friday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.saturday.start.$error || $v.customSchedule.saturday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.saturday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.saturday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.saturday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.saturday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.saturday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.sunday.start.$error || $v.customSchedule.sunday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.sunday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.sunday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.sunday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.sunday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.sunday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.languages') }}</div>
					<div class="description">{{ $t('create-user.languages-description') }}</div>
				</div>
				<div class="form">
					<div class="saved-languages" v-if="savedLanguages.length">
						<div class="box">
							<div class="group-language" v-for="languageItem in savedLanguages">
								<div class="main-label">
									<div class="name">{{ languageItem.name }}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editLanguageLevel(languageItem)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteLanguageLevel(languageItem)"><icon-trash /></button>
									</div>
								</div>
								<div class="levels-values">
									<div class="item-level">
										<div class="label">{{ $t('create-user.listening') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'listening').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'listening').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.reading') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'reading').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'reading').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.spoke-interaction') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_interaction').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_interaction').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.spoke-production') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_production').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_production').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.writing') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'writing').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'writing').level }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.language') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-globe /></div>
							<div class="icon-right" v-if="selectedLanguage != ''" @click.stop="selectedLanguage = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedLanguage"
								class="select-tags-tbf"
								v-bind:class="{populate: selectedLanguage != ''}"
								:options="optionsLanguages"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-language') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="levels-language" v-if="selectedLanguage">
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.listening') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelListening != ''" @click.stop="selectedLevelListening = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelListening"
									v-bind:class="{populate: selectedLevelListening != ''}"
									class="select-tags-tbf"
									:options="optionsListening"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.reading') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelReading != ''" @click.stop="selectedLevelReading = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelReading"
									v-bind:class="{populate: selectedLevelReading != ''}"
									class="select-tags-tbf"
									:options="optionsReading"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.spoke-interaction') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelSpInt != ''" @click.stop="selectedLevelSpInt = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelSpInt"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelSpInt != ''}"
									:options="optionsSpokenInteraction"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.spoke-production') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelSpPro != ''" @click.stop="selectedLevelSpPro = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelSpPro"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelSpPro != ''}"
									:options="optionsSpokenProduction"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.writing') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelWriting != ''" @click.stop="selectedLevelWriting = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelWriting"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelWriting != ''}"
									:options="optionsWriting"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
									<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group input-group-btn" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="add-more-btn" :class="[{'not-padd': $resize && $mq.between([1071,1150]) || $resize && $mq.below(600)}]">
								<button class="btn-tbf blue" @click="addOptionLanguage">
									<span class="text">{{ $t('create-user.add-another-language') }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group last">
				<div class="data-group">
					<div class="title">{{ $t('create-user.skills') }}</div>
					<div class="description">{{ $t('create-user.description-skills') }}</div>
				</div>
				<div class="form">
					<div class="saved-skills" v-if="savedSkills.length">
						<div class="box">
							<div class="group-skill" v-for="skillItem in savedSkills">
								<div class="main-label">
									<div class="name">{{ skillItem.name }}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editSkillLevel(skillItem)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteSkillLevel(skillItem)"><icon-trash /></button>
									</div>
								</div>
								<div class="level-skill">
									{{ skillItem.level ? $t(`skill.type.${skillItem.level}`) : $t('evaluation.not_completed') }}
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.skill') }}</label>
							<div class="create-new" @click="showModal('skill', {from: 'create-user'})">{{$t('create-user.add-skill')}}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-skill /></div>
							<div class="icon-right" v-if="selectedSkill != ''" @click.stop="selectedSkill = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedSkill"
								v-bind:class="{populate: selectedSkill != ''}"
								class="select-tags-tbf"
								:options="optionsSkills"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-skill') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group" v-if="selectedSkill" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}">
						<div class="label-header">
							<label class="label">{{ $t('create-user.level') }}</label>
							<div v-if="$v.selectedSkillLevel.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedSkillLevel.$error}">
							<div class="icon-left"><icon-level /></div>
							<div class="icon-right" v-if="selectedSkillLevel != ''" @click.stop="selectedSkillLevel = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedSkillLevel"
								v-bind:class="{populate: selectedSkillLevel != ''}"
								class="select-tags-tbf"
								:options="optionsSkillsLevels"
								:allow-empty="false"
								:show-labels="false"
								track-by="key" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-level') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group input-group-btn" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}" v-if="selectedSkill">
						<div class="add-more-btn" :class="[{'not-padd': $resize && $mq.below(1070) || $mq.between([1151,1329])}]">
							<button class="btn-tbf blue" @click="addSkill">
								<span class="text">{{ $t('create-user.add-another-skill') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>

		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({name: 'vacant-jobs'})"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!vacantJob.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/People'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconUpload from '../../Icons/Upload'
	import IconBuilding from '../../Icons/Building'
	import IconEmail from '../../Icons/Email'
	import IconMobile from '../../Icons/Mobile'
	import IconCalendar from '../../Icons/Calendar'
	import IconRole from '../../Icons/Role'
	import IconDepartment from '../../Icons/Department'
	import IconWorkSchedule from '../../Icons/WorkSchedule'
	import IconGlobe from '../../Icons/Globe'
	import IconSkill from '../../Icons/Skill'
	import IconLevel from '../../Icons/Level'
	import IconFile from '../../Icons/FilePdf'
	import IconGears from '../../Icons/Gears'
	import IconQuestion from '../../Icons/Question'

	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconPeople,
			IconEdit,
			IconTrash,
			IconUpload,
			IconBuilding,
			IconEmail,
			IconMobile,
			IconCalendar,
			IconRole,
			IconDepartment,
			IconWorkSchedule,
			IconGlobe,
			IconSkill,
			IconLevel,
			IconFile,
			IconGears,
			LoaderUsersCreate,
			IconQuestion
		},
		data() {
			return {
				loaded: false,
				vacantJob: {
					name: '',
					description: ''
				},
			  	optionsRoles: [],
			  	selectedRoles: [],
			  	optionsWorkstations: [],
			  	selectedWorkstation: '',
			  	optionsDepartments: [],
			  	selectedDepartments: [],
			  	optionsSkills: [],
				selectedSkill: '',
			  	savedSkills: [],
				optionsSkillsLevels: [{name: 'Professional' , key: 'professional'},{name: 'Medium' , key: 'medium'},{name: 'Beginner' , key: 'beginner'}],
				selectedSkillLevel: '',
				optionsLanguages: [],
				selectedLanguage: '',
			  	savedLanguages: [],
				selectedLevelListening: '',
				selectedLevelReading: '',
				selectedLevelSpInt: '',
				selectedLevelSpPro: '',
				selectedLevelWriting: '',
				optionsListening: [
					{value: 'A1', text: this.$t('create-user.listening-a1')},
					{value: 'A2', text: this.$t('create-user.listening-a2')},
					{value: 'B1', text: this.$t('create-user.listening-b1')},
					{value: 'B2', text: this.$t('create-user.listening-b2')},
					{value: 'C1', text: this.$t('create-user.listening-c1')},
					{value: 'C2', text: this.$t('create-user.listening-c2')}
				],
				optionsReading: [
					{value: 'A1', text: this.$t('create-user.reading-a1')},
					{value: 'A2',text: this.$t('create-user.reading-a2')},
					{value: 'B1',text: this.$t('create-user.reading-b1')},
					{value: 'B2',text: this.$t('create-user.reading-b2')},
					{value: 'C1',text: this.$t('create-user.reading-c1')},
					{value: 'C2',text: this.$t('create-user.reading-c2')}
				],
				optionsSpokenInteraction: [
					{value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
					{value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
					{value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
					{value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
					{value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
					{value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
				],
				optionsSpokenProduction: [
					{value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
					{value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
					{value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
					{value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
					{value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
					{value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
				],
				optionsWriting: [
					{value: 'A1',text: this.$t('create-user.writing-a1')},
					{value: 'A2',text: this.$t('create-user.writing-a2')},
					{value: 'B1',text: this.$t('create-user.writing-b1')},
					{value: 'B2',text: this.$t('create-user.writing-b2')},
					{value: 'C1',text: this.$t('create-user.writing-c1')},
					{value: 'C2',text: this.$t('create-user.writing-c2')}
				],
				optionsWorkSchedules: [],
				selectedWorkSchedule: '',
				customSchedule: {
					monday: {start: '', end: ''},
					tuesday: {start: '', end: ''},
					wednesday: {start: '', end: ''},
					thursday: {start: '', end: ''},
					friday: {start: '', end: ''},
					saturday: {start: '', end: ''},
					sunday: {start: '', end: ''}
				},
				timepickerKey: 1,
				error_message: '',
				error_email: false,
				errorsBe: {},
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name)
			};
		},
		async mounted(){
			await this.getFilters()

			if(this.$route.params.slug){
				await this.getVancatJobData()
			}

			this.$root.$on('addDropdownVacantJobCreate', (data, type) => {
				switch (type) {
					case 'role':
						this.optionsRoles.push(data)
						this.selectedRoles.push(data)
						break;
					case 'workstation':
						this.optionsWorkstations.push(data)
						this.selectedWorkstation = data
						break;
					case 'department':
						this.optionsDepartments.push(data)
						this.selectedDepartments.push(data)
						break;
					case 'skill':
						this.optionsSkills.push(data.department)
						var skillObj = {
							level: data.level.key,
							id: data.department.id,
							name: data.department.name
						}

						this.savedSkills.push(skillObj)
						this.optionsSkills.find(el => el.id == data.department.id).$isDisabled = true
						break;
				}
			})
		},
		validations: {
			vacantJob: {
				name: {required}
			},
			selectedSkillLevel: {required: requiredIf(function (model) {
				return this.selectedSkill != ''
			})},
			customSchedule: {
				monday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.monday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.monday.start
					})}
				},
				tuesday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.tuesday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.tuesday.start
					})}
				},
				wednesday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.wednesday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.wednesday.start
					})}
				},
				thursday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.thursday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.thursday.start
					})}
				},
				friday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.friday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.friday.start
					})}
				},
				saturday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.saturday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.saturday.start
					})}
				},
				sunday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.sunday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.sunday.start
					})}
				}
			},
		},
		methods: {
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
			async getFilters(){
				var filtersDB = {
					roles: true, 
					workstations: true, 
					work_schedules: this.$route.params.slug ? 'vacant_jobs' : 'roles',
					vacant_jobs_slug: this.$route.params.slug,
					foreign_languages: true, 
					skills: true, 
					departments: true, 
				}

				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
				.then(({data}) => {
					this.optionsDepartments = data.data.departments
					this.optionsRoles = data.data.roles
        			this.optionsWorkstations = data.data.workstations
        			this.optionsWorkSchedules = data.data.work_schedules
					this.optionsWorkSchedules.push({name: 'Custom'})
        			this.optionsSkills = data.data.skills
        			this.optionsLanguages = data.data.foreign_languages

					// this.optionsWorkSchedules.map(el => { el.$isDisabled = el.hasOwnProperty('role_id') &&  el.role_id != '' ? true : false })
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					if(!this.$route.params.slug){
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					}
				})
			},
			async getVancatJobData(){
				await axios.get(`/vacant-jobs/${this.$route.params.slug}/edit`)
				.then(({data}) => {
					let vacantJobData = data.data

					this.vacantJob = {
						id: vacantJobData.id,
						slug: vacantJobData.slug,
						name: vacantJobData.name,
						description: vacantJobData.description
					}
					this.selectedWorkstation = vacantJobData.workstation ? this.optionsWorkstations.find(el => el.id == vacantJobData.workstation.id) : ''
					this.selectedWorkSchedule = vacantJobData.work_schedule ? this.optionsWorkSchedules.find(el => el.id == vacantJobData.work_schedule.id) : ''
					this.savedSkills = vacantJobData.skills

					this.savedSkills.map((skill) => {
						this.optionsSkills.find(el => el.id == skill.id).$isDisabled = true
					})

					vacantJobData.roles.map((role) => {
						this.selectedRoles.push(this.optionsRoles.find(el => el.id == role.id))
					})

					vacantJobData.departments.map((department) => {
						this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == department.id))
					})

					if(vacantJobData.foreign_languages.length){
						vacantJobData.foreign_languages.map(lang => {
							var language = this.optionsLanguages.find(el => el.id == lang.id)
							language.$isDisabled
							this.savedLanguages.push({
								id: language.id,
								name: language.name,
								levels: lang.levels
							})
						})						
					}


				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var selectedRoles = this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : []
					var selectedDepartments = this.selectedDepartments.length ? this.selectedDepartments.map(function(a) {return a.id;}) : ''
					var selectedWorkScheduleObject = this.selectedWorkSchedule ? (this.selectedWorkSchedule.name == 'Custom' ? this.generateSchedule() : {id: this.selectedWorkSchedule.id} ) : {}

					var selectedSkills = [...this.savedSkills]
					if(this.selectedSkill != ''){
						var skillObj = {
							level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
							id: this.selectedSkill.id,
							name: this.selectedSkill.name
						}
					}

					var languages = [...this.savedLanguages]
					if(this.selectedLanguage != ''){
						languages.push({
							id: this.selectedLanguage.id,
							name: this.selectedLanguage.name,
							levels: [
								{type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
								{type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
								{type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
								{type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
								{type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
							]
						})
					}

					let objData = {}

					objData.name = this.vacantJob.name
					objData.description = this.vacantJob.description
					objData.workstation_id = this.selectedWorkstation ? this.selectedWorkstation.id : ''
					objData.roles = selectedRoles
					if(selectedDepartments.length) { objData.departments = selectedDepartments }
					if(Object.keys(selectedWorkScheduleObject).length) { objData.work_schedules = selectedWorkScheduleObject }
					if(selectedSkills.length) { objData.skills = selectedSkills }
					if(languages.length) { objData.foreign_languages = languages }

					if(type == 'create'){
						this.createVacantJob(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateVacantJob(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createVacantJob(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/vacant-jobs/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'vacant-jobs'})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateVacantJob(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/vacant-jobs/${this.vacantJob.slug}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'vacant-jobs'})
						}, 500)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			addOptionLanguage(){
				var languageObj = {
					id: this.selectedLanguage.id,
					name: this.selectedLanguage.name,
					levels: [
						{type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
						{type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
						{type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
						{type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
						{type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
					]
				}

				this.savedLanguages.push(languageObj)

				this.optionsLanguages.find(el => el.id == this.selectedLanguage.id).$isDisabled = true

				this.selectedLanguage = ''
				this.clearLanguagesLevels()
			},
			clearLanguagesLevels() {
				this.selectedLevelListening = ''
				this.selectedLevelReading = ''
				this.selectedLevelSpInt = ''
				this.selectedLevelSpPro = ''
				this.selectedLevelWriting = ''
			},
			editLanguageLevel(obj){
				var language_selected = this.optionsLanguages.find(el => el.id == obj.id)
				language_selected.$isDisabled = false
				this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == obj.id), 1)

				this.selectedLanguage = language_selected

				var listening = obj.levels.find((el) => el.type == 'listening')
				this.selectedLevelListening = listening.level != 'undefined' ? this.optionsListening.find(el => el.value == listening.level) : ''
				
				var reading = obj.levels.find((el) => el.type == 'reading')
				this.selectedLevelReading = reading.level != 'undefined' ? this.optionsReading.find(el => el.value == reading.level) : ''
				
				var spoke_interaction = obj.levels.find((el) => el.type == 'spoke_interaction')
				this.selectedLevelSpInt = spoke_interaction.level != 'undefined' ? this.optionsSpokenInteraction.find(el => el.value == spoke_interaction.level) : ''
				
				var spoke_production = obj.levels.find((el) => el.type == 'spoke_production')
				this.selectedLevelSpPro = spoke_production.level != 'undefined' ? this.optionsSpokenProduction.find(el => el.value == spoke_production.level) : ''
				
				var writing = obj.levels.find((el) => el.type == 'writing')
				this.selectedLevelWriting = writing.level != 'undefined' ? this.optionsWriting.find(el => el.value == writing.level) : ''
			},
			deleteLanguageLevel(language){
				this.optionsLanguages.find(el => el.id == language.id).$isDisabled = false
				this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == language.id), 1)
			},
			addSkill(){
				this.$v.selectedSkillLevel.$touch()
				if (!this.$v.selectedSkillLevel.$invalid) {
					var skillObj = {
						level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
						id: this.selectedSkill.id,
						name: this.selectedSkill.name
					}

					this.savedSkills.push(skillObj)

					this.optionsSkills.find(el => el.id == this.selectedSkill.id).$isDisabled = true

					this.selectedSkill = ''
					this.selectedSkillLevel = ''
					this.$v.selectedSkillLevel.$reset()
				}

			},
			editSkillLevel(obj){
				var skill_selected = this.optionsSkills.find(el => el.id == obj.id)
				skill_selected.$isDisabled = false
				this.savedSkills.splice(this.savedSkills.findIndex((el) => el.id == obj.id), 1)

				this.selectedSkill = skill_selected
				this.selectedSkillLevel = obj.level != 'undefined' ? this.optionsSkillsLevels.find(el => el.key == obj.level) : ''
			},
			deleteSkillLevel(skill){
				if(this.optionsSkills.find(el => el.id == skill.id)){
					this.optionsSkills.find(el => el.id == skill.id).$isDisabled = false
				}
				this.savedSkills.splice(this.savedSkills.findIndex((el) => el.skill == skill), 1)
			},
			generateSchedule(){
				var schedules = []

				if(this.customSchedule.monday.start && this.customSchedule.monday.end){
					var mondayObj = {day: 'monday', start_time: this.customSchedule.monday.start, end_time: this.customSchedule.monday.end}
					schedules.push(mondayObj)
				}
				if(this.customSchedule.tuesday.start && this.customSchedule.tuesday.end){
					var tuesdayObj = {day: 'tuesday', start_time: this.customSchedule.tuesday.start, end_time: this.customSchedule.tuesday.end}
					schedules.push(tuesdayObj)
				}
				if(this.customSchedule.wednesday.start && this.customSchedule.wednesday.end){
					var wednesdayObj = {day: 'wednesday', start_time: this.customSchedule.wednesday.start, end_time: this.customSchedule.wednesday.end}
					schedules.push(wednesdayObj)
				}
				if(this.customSchedule.thursday.start && this.customSchedule.thursday.end){
					var thursdayObj = {day: 'thursday', start_time: this.customSchedule.thursday.start, end_time: this.customSchedule.thursday.end}
					schedules.push(thursdayObj)
				}
				if(this.customSchedule.friday.start && this.customSchedule.friday.end){
					var fridayObj = {day: 'friday', start_time: this.customSchedule.friday.start, end_time: this.customSchedule.friday.end}
					schedules.push(fridayObj)
				}
				if(this.customSchedule.saturday.start && this.customSchedule.saturday.end){
					var saturdayObj = {day: 'saturday', start_time: this.customSchedule.saturday.start, end_time: this.customSchedule.saturday.end}
					schedules.push(saturdayObj)
				}
				if(this.customSchedule.sunday.start && this.customSchedule.sunday.end){
					var sundayObj = {day: 'sunday', start_time: this.customSchedule.sunday.start, end_time: this.customSchedule.sunday.end}
					schedules.push(sundayObj)
				}

				var objCustomSchedule = {
					name: `Custom ${this.vacantJob.name}`,
					schedules: schedules
				}

				return objCustomSchedule
			}
		}
	};
</script>

<style lang="scss" scoped>
	.custom-file-error{
		color: #FB5454;
		font-size: 12px;
		width: 100%;
		text-align: right;
		margin-top: 10px;
	}
</style>